import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import FlipCard, { FlipCardProps } from './FlipCard'

export type FlipCardSectionProps = {
  id: string
}
const config: FlipCardProps[] = [
  {
    id: 'sprint',
    title: 'The Sprint',
    text: 'The heart of eduScrum contains all the events required to achieve learning objectives. This is a context-rich lesson series or project and the duration is timeboxed to approximately 7 weeks.',
    image: null
  },
  {
    id: 'planning',
    title: 'Sprint Planning',
    text: 'The event at the beginning of the Sprint  includes Team Formation, explaining of Learning Objectives, and Planning of the work for the Sprint.',
    image: null
  },
  {
    id: 'standup',
    title: 'Standup',
    text: 'The event at the beginning of the lesson takes 5 minutes. The Student Team meets to plan their work and monitor progress using eduScrum board - Flap.',
    image: null
  },
  {
    id: 'review',
    title: 'Sprint Review',
    text: 'Repeatedly during the Sprint, the Student Teams present what they achieved and collect feedback from The Teacher and other teams.',
    image: null
  },
  {
    id: 'retro',
    title: 'Sprint Retrospective',
    text: 'Teams reflect at the end of the Sprint how the Sprint went with regards to people, relationships, processes, and tools. This is the time for team evaluation but also for individual team members reflection.',
    image: null
  }
]

const SplitSection: FC<FlipCardSectionProps> = ({ id }) => {
  const images: IGatsbyImageData[] = useStaticQuery(graphql`
    query FlipCardsQuery {
      planning: file(relativePath: { eq: "eventsPlanning1.png" }) {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
      }
      standup: file(relativePath: { eq: "eventsPlanning2.png" }) {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
      }
      retro: file(relativePath: { eq: "eventsRetro.png" }) {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
      }
      sprint: file(relativePath: { eq: "eventsSprint.png" }) {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
      }
      review: file(relativePath: { eq: "eventsReview.png" }) {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
      }
    }
  `)
  const configWithImages = config.map((z: FlipCardSectionProps) => ({
    ...z,
    image: images[z.id]
  }))
  return (
    <section id={id} className="container mx-auto px-4 lg:px-16 text-center pb-20">
      <div className="container mx-auto mb-4 text-2xl lg:text-3xl font-semibold font-display leading-relaxed">
        eduScrum events
      </div>
      <div className="flex flex-row flex-wrap justify-around">
        {configWithImages.map((i) => (
          <FlipCard {...i} />
        ))}
      </div>
    </section>
  )
}

export default SplitSection
