import React, { FC } from 'react'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

export type WhatIsEduscrumTileProps = {
  title: string
  description: string
  id: string
  image?: IGatsbyImageData
}
const WhatIsEduscrumTile: FC<WhatIsEduscrumTileProps> = ({ title, description, id, image }) => (
  <div className="w-full lg:w-1/2 flex flex-col mb-10">
    <div className="mb-4 mx-auto px-0 lg:px-8">
      <GatsbyImage alt={title} image={getImage(image)} />
    </div>
    <div className="mb-4 mx-auto text-2xl lg:text-3xl font-display font-bold text-center leading-relaxed">
      {title}
    </div>
    <div className="mb-4 mx-auto text-lg lg:text-xl font-body font-light text-center w-80 leading-relaxed">
      {description}
    </div>
  </div>
)

export default WhatIsEduscrumTile
