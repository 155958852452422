import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import WhatIsEduscrumTile, { WhatIsEduscrumTileProps } from './WhatIsEduscrumTile'
import { IGatsbyImageData } from 'gatsby-plugin-image'

const config: WhatIsEduscrumTileProps[] = [
  {
    title: 'Students have autonomy',
    id: 'autonomy',
    description: 'Responsibility for the learning process is delegated from the teacher to the students.'
  },
  {
    title: 'Learning becomes enjoyable',
    id: 'enjoyable',
    description:
      'Curiosity & creativity are incorporated into the learning process, making it more interesting and engaging.'
  },
  {
    title: 'Agile Mindset',
    id: 'mindset',
    description:
      'Teachers and students develop an Agile mindset that helps them to collaborate and adapt to changes.'
  },
  {
    title: 'Learning from experience',
    id: 'experience',
    description: 'eduScrum is founded on empiricism: learning comes from experience.'
  }
]

type WhatIsEduscrumProps = {
  id: string
}
const WhatIsEduscrum: FC<WhatIsEduscrumProps> = ({ id }) => {
  const images: IGatsbyImageData[] = useStaticQuery(graphql`
    query WhatImagesQuery {
      autonomy: file(relativePath: { eq: "whatAutonomy.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      enjoyable: file(relativePath: { eq: "whatEnjoyable.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      mindset: file(relativePath: { eq: "whatMindset.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      experience: file(relativePath: { eq: "whatExperience.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const configWithImages = config.map((z: WhatIsEduscrumTileProps) => ({
    ...z,
    image: images[z.id]
  }))
  return (
    <section id={id} className="pb-20">
      <div className="container mx-auto px-4 lg:px-16 items-center flex flex-col lg:flex-row lg:flex-wrap">
        {configWithImages.map((x: WhatIsEduscrumTileProps) => (
          <WhatIsEduscrumTile {...x} key={`whatEduscrumTile-${x.id}`} />
        ))}
      </div>
    </section>
  )
}

export default WhatIsEduscrum
