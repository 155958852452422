import React, { FC } from 'react'

type FullWidthTitleSectionProps = {
  id: string
  text?: string
}
const FullWidthTitleSection: FC<FullWidthTitleSectionProps> = ({ id, children, text = null }) => (
  <section id={id} className="my-auto px-4 lg:px-16 py-20 2xl:pt-40 text-center">
    <h2 className="text-4xl md:text-5xl xl:text-6xl font-bold font-display leading-relaxed mb-4 lg:mb-8">
      {children}
    </h2>
    {text && (
      <p className="text-lg lg:text-xl max-w-screen-sm mx-auto font-body font-light leading-relaxed">
        {text}
      </p>
    )}
  </section>
)

export default FullWidthTitleSection
