import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import EduscrumRolesTile, { EduscrumRolesTileProps } from './EduscrumRolesTile'

const config = [
  {
    title: 'The Teacher',
    id: 'po',
    description: 'As the Product Owner and Scrum Master provides students with WHY and WHAT.'
  },
  {
    title: 'The Student Team',
    id: 'student',
    description:
      '4-5 students who collaborate to achieve learning objectives. They decide HOW they will do it.'
  },
  {
    title: 'The Team Captain',
    id: 'captain',
    description:
      'The member of the Student Team who makes sure the team performs well and works together.'
  }
]

type EduscrumRolesProps = {
  id: string
}
const EduscrumRoles: FC<EduscrumRolesProps> = ({ id }) => {
  const images: IGatsbyImageData[] = useStaticQuery(graphql`
    query EduscrumRolesQuery {
      captain: file(relativePath: { eq: "rolesCaptain.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      student: file(relativePath: { eq: "rolesStudent.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      po: file(relativePath: { eq: "rolesTeacher.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const configWithImages = config.map((z: EduscrumRolesTileProps) => ({
    ...z,
    image: images[z.id]
  }))
  return (
    <section id={id} className="flex flex-col pb-20">
      <div className="container mx-auto text-center mb-4 text-2xl lg:text-3xl font-semibold font-display leading-relaxed">
        eduScrum roles
      </div>
      <div className="container mx-auto px-4 lg:px-16 items-center flex flex-row flex-wrap">
        {configWithImages.map((x) => (
          <EduscrumRolesTile key={x.id} {...x} />
        ))}
      </div>
    </section>
  )
}

export default EduscrumRoles
