import React, { FC } from 'react'
const getGradient = (x: string) => {
  switch (x) {
    case 'green':
      return 'bg-gradient-to-br from-brandGreen-lighter to-brandGreen-darker'
    case 'yellow':
      return 'bg-gradient-to-br from-brandYellow-lighter to-brandYellow-darker'
    case 'blue':
      return 'bg-gradient-to-br from-brandBlue-lighter to-brandBlue-darker'
    case 'red':
    default:
      return 'bg-gradient-to-br from-primary-lighter to-primary-darker'
  }
}

type GradientTextProps = {
  color?: 'green' | 'yellow' | 'blue' | 'red'
}
const GradientText: FC<GradientTextProps> = ({ children, color }) => (
  <span className={`text-transparent bg-clip-text ${getGradient(color)}`}>{children}</span>
)

export default GradientText
