import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC, ReactElement } from 'react'
import SplitSectionItem, { SplitSectionItemProps } from './SplitSectionItem'

export type SplitSectionProps = {
  id: string
}
const config: SplitSectionItemProps[] = [
  {
    id: 'empowerment',
    title: 'Student empowerment',
    description:
      'The teacher decides WHAT  the learning objectives are and explains WHY they need to be achieved but it is up to the students to make the decision about HOW they will do it.',
    reverseOrder: false
  },
  {
    id: 'century',
    title: 'Developing 21st Century skills',
    description:
      'To thrive in the current, rapidly changing world, students need more than literacy, numeracy, or science skills. eduScrum framework helps them  develop 21st century skills, specifically in the 4Cs: Communication, Collaboration, Critical Thinking & Creativity.',
    reverseOrder: true
  },
  {
    id: 'motivation',
    title: 'Motivation',
    description:
      'eduScrum gives students a chance to discover their strengths and learn about themselves in a unique and relevant way. It enhances their curiosity leading them to increased motivation and better results.',
    reverseOrder: false
  }
]

const SplitSection: FC<SplitSectionProps> = ({ id }) => {
  const images: IGatsbyImageData[] = useStaticQuery(graphql`
    query SplitSectionImagesQuery {
      century: file(relativePath: { eq: "whyCentury.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      empowerment: file(relativePath: { eq: "whyEmpowerment.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      motivation: file(relativePath: { eq: "whyMotivation.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const configWithImages = config.map((z: SplitSectionItemProps) => ({
    ...z,
    image: images[z.id]
  }))
  return (
    <section id={`splitSection-${id}`} className="pb-20">
      {configWithImages.map((i) => (
        <SplitSectionItem {...i} />
      ))}
    </section>
  )
}

export default SplitSection
