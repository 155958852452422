import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC, ReactElement } from 'react'

export type FlipCardProps = {
  id: string
  title: string
  text: string
  image: IGatsbyImageData
}
const FlipCard: FC<FlipCardProps> = ({ id, image, title, text }) => (
  <div id={id} className="flip m-4 lg:m-8 mb-8">
    <div className="flip-content">
      <div className="flip-front">
        <div className="flip-gradient"></div>
        <div className="flip-front-title text-center text-xl lg:text-2xl font-semibold font-display leading-relaxed">
          <span>{title}</span>
        </div>
        <GatsbyImage className="rounded-lg" objectFit="cover" image={getImage(image)} alt={title} />
      </div>
      <div className="flip-back p-4">
        <div className="text-center text-xl lg:text-2xl font-semibold font-display leading-relaxe mb-4">
          {title}
        </div>
        <div className="text-center text-xl font-body font-light mb-4">{text}</div>
      </div>
    </div>
  </div>
)

export default FlipCard
