import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from '../components/Button'
import Layout from '../components/layout/Layout'
import SplitSection from '../components/sections/SplitSection/SplitSection'
import GradientText from '../components/GradientText'
import FullWidthTitleSection from '../components/FullWidthTitleSection'
import { Link } from 'gatsby'
import WhatIsEduscrum from '../components/sections/WhatSection/WhatIsEduscrum'
import EduscrumRoles from '../components/sections/EduscrumRoles/EduscrumRoles'
import FlipCardsSection from '../components/sections/FlipCardsSection/FlipCardsSection'
import SEO from '../components/SEO'

const Index = ({ data }) => {
  const howEduscrumWorksImage = getImage(data.howEduscrumWorksImage)
  const eduFlapImage = getImage(data.eduFlapImage)
  return (
    <Layout>
      <SEO
        title="Discover eduScrum"
        description="What is eduScrum? Why eduScrum? How does it work?"
      />
      <FullWidthTitleSection
        id="what-is-eduscrum"
        text="eduScrum is a framework that brings innovative solutions from an IT Product Development to Education. It promotes team collaboration and allows students to take responsibility for their own learning process."
      >
        <GradientText color="blue">What </GradientText> is eduScrum?
      </FullWidthTitleSection>
      <WhatIsEduscrum id="what-eduscrum-tiles" />
      <FullWidthTitleSection
        id="why-eduscrum"
        text="Why not eduScrum?! It is a proven way to enhance the learning experience and keep young minds focused and excited! eduScrum is for both the students and the teachers too. Everyone is a part of it!"
      >
        <GradientText color="yellow">Why </GradientText>eduScrum?
      </FullWidthTitleSection>
      <SplitSection id="why-eduscrum" />
      <FullWidthTitleSection
        id="how-does-it-work"
        text="eduScrum as a framework has roles, ceremonies & artifacts developed to support students in their learning process."
      >
        <GradientText color="green">How </GradientText> does it work?
      </FullWidthTitleSection>
      <section className="container mx-auto px-4 lg:px-20 xl:px-80 text-center pb-20">
        <GatsbyImage image={howEduscrumWorksImage} alt="How eduscrumWorks" />
      </section>
      <EduscrumRoles id="eduscrum-roles" />
      <FlipCardsSection id="flip-cards-section" />
      <section className="container mx-auto px-4 lg:px-20 xl:px-80 text-center pb-20">
        <div className="container mx-auto mb-4 text-2xl lg:text-3xl font-semibold font-display leading-relaxed">
          eduScrum artifacts
        </div>
        <div className="container text-lg lg:text-xl max-w-screen-sm mx-auto font-body font-light leading-relaxed pb-8">
          Content of assignment, The Flap - eduScrum board, Definition of Doing, Definition of
          Communication, Definition of Fun
        </div>

        <GatsbyImage image={eduFlapImage} alt="Edu flap" />
      </section>
      <section className="mx-auto my-20 py-24 bg-gray-100 text-center">
        <h3 className="text-5xl font-semibold font-display">Would you like to learn more?</h3>
        <p className="mt-8">
          <Link to="/training#register-form">
            <Button size="xl">Register for training</Button>
          </Link>
        </p>
      </section>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query DiscoverEduscrumQuery {
    howEduscrumWorksImage: file(relativePath: { eq: "HowEduscrumWorks.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    eduFlapImage: file(relativePath: { eq: "EduFlap.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
