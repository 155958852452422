import React, { FC } from 'react'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

export type EduscrumRolesTileProps = {
  title: string
  description: string
  id: string
  image?: IGatsbyImageData
}
const EduscrumRolesTile: FC<EduscrumRolesTileProps> = ({ title, description, id, image }) => (
  <div className="w-full lg:w-1/3 flex flex-col">
    <div className="w-full mb-4 mx-auto px-0 lg:px-8">
      <GatsbyImage className="rounded-lg" objectFit="cover" image={getImage(image)} alt={title} />
    </div>
    <div className="mb-4 text-center text-xl lg:text-2xl font-semibold font-display leading-relaxed">{title}</div>
    <div className="mb-4 mx-auto text-center  text-lg lg:text-xl font-body font-light w-80">{description}</div>
  </div>
)

export default EduscrumRolesTile
